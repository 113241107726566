import React, { Component } from 'react'

export default class LogoLogin extends Component {
    render() {
        return (
            <div className="logo-login">
            </div>
        )
    }
}
