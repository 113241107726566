import React, { Component, Fragment } from "react";

import MapContainer from "../../components/molecule/Map";
import Btn from "../../components/atom/Btn";
import Breadcrumb from "../../components/organism/Breadcrumb";

class TRT200SaisieSerial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            retourData: this.props.location.state.retourData,
            numChambre: this.props.location.state.numChambre,
            chambreNonTrouvee: false,
            chambreTrouvee: false,
            serialSaisi: false,
            serialCorrespond: false,
            serial: "",
            errors: []
        };
    }

    handleSerialChange = (event) => {
        this.setState({ serial: event.target.value.toUpperCase() });
    };

    handleSubmitSerial = (event) => {
        event.preventDefault();

        // compare avec le retour de letape precedente
        if (this.state.serial !== this.state.retourData.serialCentrale) {
            this.props.history.push({
                pathname: "/TRT200SerialKO",
                state: {
                    numChambre: this.state.numChambre,
                    serial: this.state.serial
                }
            });
        } else {
            this.props.history.push({
                pathname: "/TRT200Detail",
                state: {
                    numChambre: this.state.numChambre
                }
            });
        }
    };

    render() {
        const retourData = this.state.retourData;

        return (
            <Fragment>
                <Breadcrumb
                    titre="TRT200"
                    ico={sessionStorage.getItem("icoMenu")}
                />
                <div className="vertical-center text-center">
                    <div className="ico__md ico--location ico--center"></div>
                    <h3 className="vertical-page__item succes">
                        Chambre {retourData.nomChambre} prête
                    </h3>
                    <p className="vertical-page__item text-bold">
                        Localisation
                    </p>
                    <p className="vertical-page__item mb-xxl">
                        {retourData.adresse}
                    </p>

                    <div className="map-container vertical-page__item mb-md">
                        <MapContainer
                            lat={retourData.lat}
                            lng={retourData.lng}
                            zoom={18}
                        />
                    </div>

                    <div className="ico__xl ico--center ico--code mt-xxl"></div>
                    <p className="vertical-page__item mb-xl">
                        Veuillez saisir le numéro S/N du boitier GPRS à
                        installer dans cette chambre
                    </p>
                    <form
                        className="vertical-page__item"
                        method="POST"
                        onSubmit={this.handleSubmitSerial}
                    >
                        <input
                            type="text"
                            id="serial"
                            onChange={this.handleSerialChange}
                            placeholder="serial"
                        />
                        {this.state.errors.serial ? (
                            <div> {this.state.errors.serial}</div>
                        ) : (
                            ""
                        )}

                        <Btn
                            btnCss="btn btn-primary mt-md"
                            type="submit"
                            id="valider"
                        >
                            Valider
                        </Btn>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default TRT200SaisieSerial;
