import React, { Component, Fragment } from "react";

import Breadcrumb from "../../components/organism/Breadcrumb";
import { Link } from "react-router-dom";

class TRT200ChambreKO extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumb
                    titre="TRT200"
                    ico={sessionStorage.getItem("icoMenu")}
                />
                <div className="vertical-center text-center">
                    <div className="ico__xl ico--center ico--alert"></div>
                    <h1 className="alert vertical-page__item mt-sm mb-xl">
                        Erreur, chambre non trouvée
                    </h1>
                    <p className="vertical-page__item mt-sm mb-xl">
                        Impossible de trouver la chambre. Veuillez contacter les
                        personnes suivantes afin de régler le problème.
                    </p>
                    <h3 className="vertical-page__item">
                        <strong>Yoan Fagis</strong> <br /> 06 70 06 57 93
                    </h3>
                    <h3 className="vertical-page__item mb-xxl">
                        <strong>Arnaud Sellier</strong> <br /> 06 49 33 08 85
                    </h3>
                    <Link to="/TRT200">
                        <button className="vertical-page__item btn btn-primary mt-xxl">
                            Rechercher autre Chambre
                        </button>
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default TRT200ChambreKO;
