import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/organism/Breadcrumb'
import axios from 'axios'
import Spinner from "../../../components/Spinner"


class TesteurSigfoxNonVu extends Component {

    constructor(props){
        super(props)
        this.state = {
            serial: this.props.location.state.serial,
            errors: [],
        }
    }

    async handleReload(event) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/Sigfox/testeur",
                bodyFormData,
                config
            )
            .then((res) => {
                document.getElementById("spinnerTesteurReload").style.display =
                    "none";
                if (res.data.data.trouve === true) {
                    this.setState({ detail: res.data.data });
                } else {
                    this.props.history.push({
                        pathname: "/TesteurSigfoxNonVu",
                        state: { serial: this.state.serial }
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/TesteurSigfoxInconnu",
                    state: {
                        serial: this.state.serial
                    }
                });
            });
    }
    
    render() {
        return (
            <Fragment>
                <Spinner spinnerId="spinnerTesteurReload"></Spinner>
                <Breadcrumb titre='Testeur Sigfox' ico={sessionStorage.getItem('icoMenu')} />
                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--alert ico--center"></div>
                    <h3 className="text-alert text-center mt-xl"> Serial { this.state.serial } non vu.</h3>
                    <p className="vertical-center__item text-center ">Au cours des dernières minutes, aucune communication n’a été reçue de la part du testeur.</p>
             
                    <Link className= "btn btn-primary text-center mt-xxxl mb-0 ico__animation" to="#" onClick={() => { document.getElementById( "spinnerTesteurReload" ).style.display = "block"; this.handleReload(); }}> <i className= "ico__sm ico--ib ico--reload " ></i> Tester à nouveau </Link>
                    <Link className="btn btn-primary text-center mb-xxxl" to="TesteurLoraSaisi">  Entrer un nouveau identifiant de testeur LoRaWAN </Link>
                    
                <div className="card">
                    <h5><i className="ico__sm ico--ib ico--tool pr-md"></i> Dépannage</h5>
                    <ul className="list-simple">
                        <li>Penser à vérifier qu’il n’y ai pas d’erreur de saisie pour l’identifiant.</li>
                        <li>TODO : Repasser l’aimant sur l’étiquette du boitier et attendez au moins 1 minute avant de recharger cette page.</li>
                        <li>TODO : Ne laisser pas l’aimant posé sur l’étiquette.</li>
                        <li>TODO : Réessayer en rapprochant le boitier d’une ouverture.</li>
                    </ul>
                </div>

                </div>
            </Fragment>
        )
    }
}

export default TesteurSigfoxNonVu