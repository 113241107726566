import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "./global/Login";
import Home from "./global/Home";
// import Register from './global/Register'

import TRT200 from "./trt200/TRT200";
import TRT200Detail from "./trt200/TRT200Detail";
import TRT200Rapport from "./trt200/TRT200Rapport";
import TRT200RapportOK from "./trt200/TRT200RapportOK";
import TRT200RapportKO from "./trt200/TRT200RapportKO";
import TRT200SaisieSerial from "./trt200/TRT200SaisieSerial";
import TRT200ChambreKO from "./trt200/TRT200ChambreKO";
import TRT200SerialKO from "./trt200/TRT200SerialKO";

import LoraReveil from "./lora/LoraReveil";
import LoraSaisie from "./lora/LoraSaisie";
import LoraDetail from "./lora/LoraDetail";
import LoraNonVu from "./lora/LoraNonVu";

import SigfoxReveil from "./sigfox/SigfoxReveil";
import SigfoxSaisie from "./sigfox/SigfoxSaisie";
import SigfoxNonVu from "./sigfox/SigfoxNonVu";
import SigfoxDetail from "./sigfox/SigfoxDetail";

import CngSimActivation from "./cng-sim/CngSimActivation";
import CngSimActive from "./cng-sim/CngSimActive";
import CngSimEnCours from "./cng-sim/CngSimEnCours";
import CngSimErreur from "./cng-sim/CngSimErreur";
import CngSimDejaDemandee from "./cng-sim/CngSimDejaDemandee";

import LeakageConfiguration from "./leakage/LeakageConfiguration";
import LeakageReveil from "./leakage/LeakageReveil";
import LeakageSaisie from "./leakage/LeakageSaisie";
import LeakageNonVu from "./leakage/LeakageNonVu";
import LeakageInconnu from "./leakage/LeakageInconnu";
import LeakageDetail from "./leakage/LeakageDetail";
// Je propose de nommer ASigfox le lien vers pompiers pour pas mettre le nom dans le texte

import TesteurSigfoxReveil from "./testeur/sigfox/TesteurSigfoxReveil";
import TesteurSigfoxSaisi from "./testeur/sigfox/TesteurSigfoxSaisi";
import TesteurSigfoxDetail from "./testeur/sigfox/TesteurSigfoxDetail";
import TesteurSigfoxNonVu from "./testeur/sigfox/TesteurSigfoxNonVu";
import TesteurSigfoxInconnu from "./testeur/sigfox/TesteurSigfoxInconnu";

import TesteurLoraReveil from "./testeur/lora/TesteurLoraReveil";
import TesteurLoraSaisi from "./testeur/lora/TesteurLoraSaisi";
import TesteurLoraDetail from "./testeur/lora/TesteurLoraDetail";
import TesteurLoraNonVu from "./testeur/lora/TesteurLoraNonVu";
import TesteurLoraInconnu from "./testeur/lora/TesteurLoraInconnu";


class AppRouter extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/Login" component={Login} />
                <Route path="/Home" component={Home} />

                <Route path="/TRT200" component={TRT200} />
                <Route path="/TRT200Detail" component={TRT200Detail} />
                <Route path="/TRT200Rapport" component={TRT200Rapport} />
                <Route path="/TRT200RapportOK" component={TRT200RapportOK} />
                <Route path="/TRT200RapportKO" component={TRT200RapportKO} />
                <Route path="/TRT200SaisieSerial" component={TRT200SaisieSerial} />
                <Route path="/TRT200ChambreKO" component={TRT200ChambreKO} />
                <Route path="/TRT200SerialKO" component={TRT200SerialKO} />

                <Route path="/LoraReveil" component={LoraReveil} />
                <Route path="/LoraSaisie" component={LoraSaisie} />
                <Route path="/LoraDetail" component={LoraDetail} />
                <Route path="/LoraNonVu" component={LoraNonVu} />

                <Route path="/SigfoxReveil" component={SigfoxReveil} />
                <Route path="/SigfoxSaisie" component={SigfoxSaisie} />
                <Route path="/SigfoxNonVu" component={SigfoxNonVu} />
                <Route path="/SigfoxDetail" component={SigfoxDetail} />

                <Route path="/CngSimActivation" component={CngSimActivation} />
                <Route path="/CngSimDejaDemandee" component={CngSimDejaDemandee} />
                <Route path="/CngSimActive" component={CngSimActive} />
                <Route path="/CngSimEnCours" component={CngSimEnCours} />
                <Route path="/CngSimErreur" component={CngSimErreur} />

                <Route path="/LeakageConfiguration" component={LeakageConfiguration} />
                <Route path="/LeakageReveil" component={LeakageReveil} />
                <Route path="/LeakageSaisie" component={LeakageSaisie} />
                <Route path="/LeakageNonVu" component={LeakageNonVu} />
                <Route path="/LeakageInconnu" component={LeakageInconnu} />
                <Route path="/LeakageDetail" component={LeakageDetail} />

                <Route path="/TesteurSigfoxReveil" component={TesteurSigfoxReveil} />
                <Route path="/TesteurSigfoxSaisi" component={TesteurSigfoxSaisi} />
                <Route path="/TesteurSigfoxNonVu" component={TesteurSigfoxNonVu} />
                <Route path="/TesteurSigfoxInconnu" component={TesteurSigfoxInconnu} />
                <Route path="/TesteurSigfoxDetail" component={TesteurSigfoxDetail} />

                <Route path="/TesteurLoraReveil" component={TesteurLoraReveil} />
                <Route path="/TesteurLoraSaisi" component={TesteurLoraSaisi} />
                <Route path="/TesteurLoraDetail" component={TesteurLoraDetail} />
                <Route path="/TesteurLoraNonVu" component={TesteurLoraNonVu} />
                <Route path="/TesteurLoraInconnu" component={TesteurLoraInconnu} />


                {/* <Route path="/RegisterUtilisateurs" component={Register} /> */}
            </Switch>
        );
    }
}

export default AppRouter;
