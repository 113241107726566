import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/organism/Breadcrumb";

class TRT200SerialKO extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumb
                    titre="TRT200"
                    ico={sessionStorage.getItem("icoMenu")}
                />
                <div className="vertical-center text-center">
                    <div className="ico__md ico--center ico--alert mb-md"></div>
                    <h3 className="vertical-page__item succes mb-xxs">
                        Chambre {this.props.location.state.numChambre} prete
                    </h3>
                    <h3 className="vertical-page__item alert mb-xxl">
                        Serial : {this.props.location.state.serial}{" "}
                    </h3>
                    <p className="vertical-page__item mt-xxl">
                        Le numéro de boitier ne correspond pas à cette chambre.{" "}
                        <br />
                        <br /> Veuillez vérifier qu’il soit correct sinon
                        contacter les personnes suivantes afin de régler le
                        problème.
                    </p>
                    <h3 className="vertical-page__item mt-xxl mb-xxl">
                        Yoan Fagis
                        <br />
                        06 70 06 57 93
                        <br />
                        <br />
                        <br />
                        Arnaud Sellier
                        <br />
                        06 49 33 08 85
                    </h3>
                    <Link to="/TRT200">
                        <button className="vertical-page__item btn btn-primary mt-xxl">
                            Rechercher autre Chambre
                        </button>
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default TRT200SerialKO;
