import React, { Component, Fragment } from "react";
import Btn from "../../components/atom/Btn";
import axios from "axios";
import Breadcrumb from "../../components/organism/Breadcrumb";

export default class LeakageSaisie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            retourData: []
        };
    }

    handleSerialChange = (event) => {
        this.setState({ serial: event.target.value.toUpperCase() });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/Leakage/detail",
                bodyFormData,
                config
            )
            .then((res) => {
                if (res.data.data.trouve === true) {
                    this.props.history.push({
                        pathname: "/LeakageDetail",
                        state: {
                            detail: res.data.data
                        }
                    });
                } else {
                    this.props.history.push({
                        pathname: "/LeakageNonVu",
                        state: { serial: res.data.data.serial }
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/LeakageInconnu",
                    state: {
                        token: this.state.serial
                    }
                });
            });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="Leakage" ico="ico--leakage" />

                <div className="vertical-center text-center wrapper-90">
                    <div className="ico__xl ico--code ico--center"></div>

                    <p className="vertical-center__item">
                        Entrer le numéro* de série du boitier que vous voulez
                        installer.
                    </p>

                    <form className="vertical-center__item" method="POST" onSubmit={this.handleSubmit} >
                        <input type="text" id="serial" onChange={this.handleSerialChange} placeholder="N° de boitier" />
                        {this.state.errors.serial ? (
                            <div> {this.state.errors.serial}</div>
                        ) : (
                            ""
                        )}

                        <Btn btnCss="btn btn-primary mt-md" type="submit" id="submit"> Suivant </Btn>
                    </form>

                    <blockquote>
                        *Le numéro de série du boitier se compose de 8
                        caractères : chiffres ou lettres de A à F. Il ne faut
                        pas d’espace. * Exemple : 219003A0
                    </blockquote>
                </div>
            </Fragment>
        );
    }
}
