import React, { Component, Fragment } from 'react'



/*
couleur marqueur : { this.state.retourLoRa.couleurEtatSonde } , num : { this.state.retourLoRa.serial }, etat : { this.state.retourLoRa.recu ? 'recu' : 'non recu'} <br />
couleur marqueur : { this.state.retourLoRa.couleurEtatRadio }, radio : { this.state.retourLoRa.texteEtatRadio } ( { this.state.retourLoRa.nbStations } stations )<br />
dernier envoi : { this.state.retourLoRa.dernierEnvoi }
*/
export default class InfosLoRa extends Component {

    constructor(props){
        super(props)
        this.props = props
    }

    getIconFromText(color) {
        switch(color) {
          case 'vert':
            return 'bubble__ico--succes';
          case 'rouge':
            return 'bubble__ico--alert';
          case 'orange':
            return 'bubble__ico--warning';
          default:
            return null;
        }
      }

    render() {
        if (this.props.retour.recu === 'non'){
            return (
              <ul className="bubble">
                <li>
                    <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                    <span className="bubble__content"> LoRa { this.props.retour.serial } jamais recu</span>
                </li>
              </ul>
            )
        }
    
        return (
          <Fragment>
            <ul className="bubble">
                <li>
                    <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                    <span className="bubble__content"> LoRa { this.props.retour.serial }</span>
                </li>
                <li>
                    <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                    <span> Dernier envoi : <strong>{ this.props.retour.dernierEnvoi } </strong></span>
                </li>
                <li>
                    <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatRadio)}`}></i>
                    <span> Etat radio : <strong> { this.props.retour.texteEtatRadio } { this.props.retour.nbStations } station(s)</strong></span>
                </li>
            </ul>
          </Fragment>
        )
    }
}