import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../components/organism/Breadcrumb'
import { Link } from 'react-router-dom'

export default class SigfoxReveil extends Component {

    render() {
        return (
            <Fragment>
                <Breadcrumb titre='Sigfox' ico='ico--sigfox' />
                <div className="vertical-center text-center wrapper-90">
                    <div className="ico__xl ico--compass ico--center"></div>
                    <p className="vertical-center__item text-center mb-xxl">Veuillez réveiller* le boitier en passant l’aimant sur l’étiquette du boitier.</p>
                    <Link className="btn btn-primary mt-xxl" to="SigfoxSaisie">Suivant</Link>
                    <blockquote>*Le réveil et l’envoi des informations peut prendre jusqu’à 2 minutes. </blockquote>
                </div>
            </Fragment>
        )
    }
}