import React, { Component } from 'react'

export default class Releve extends Component {
    constructor(props){
        super(props)
        this.props = props
        this.state = {
            vraiNum: this.props.num+1,
            recue: this.props.retour.recue
        } 
    } 

    getIconFromText(text) {
        switch(text) {
          case 'temperature':
            return 'ico--temperature';

          case 'hygrometrie':
            return 'ico--water';

          case 'eau':
            return 'ico--water';

          case 'pression':
            return 'ico--pression';

          case 'conductivite':
            return 'ico--conductivite';
            
          default:
            return null;
        }
    }

    render() {
        if (this.state.recue === true) {
            return (
                <div className="releve wrapper-90">
                    <i className={`releve__ico ${this.getIconFromText(this.props.type)}`}></i>
                    <div className="releve__value">
                        <p className="primary"><strong>Sonde {this.props.type} { this.state.vraiNum }</strong></p>
                        <p>Relevé du : <strong>{this.props.dateReleve}</strong></p>
                        <p>{this.props.type} : <strong>{this.props.retour.valeur} {this.props.retour.unite}</strong></p>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="releve wrapper-90">
                    <i className={`releve__ico ${this.getIconFromText(this.props.type)}`}></i>
                    <div className="releve__value">
                        <p className="primary"><strong>Sonde { this.state.vraiNum }</strong></p>
                        <p><strong>La sonde { this.state.vraiNum } n'a pas été reçue lors du dernier relevé.</strong></p>
                    </div>
                </div>
            )
        }

        
    }
}