import React, { Component } from 'react'

export default class AlarmeTRT200 extends Component {

    constructor(props){
        super(props)
        this.props = props
    }

    getIconFromText(color) {
        switch(color) {
          case 'vert':
            return 'bubble__ico--succes';
          case 'rouge':
            return 'bubble__ico--alert';
          case 'orange':
            return 'bubble__ico--warning';
          default:
            return null;
        }
      }


    render() {
      
        if (this.props.retour.couleurEtatSonde === 'rouge'){
            return (
              <ul className="bubble">
                <li className="text-bold text-alert">
                    <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                    <span className="bubble__content">{ this.props.retour.nom } : <strong> alarme en cours </strong> </span>
                </li>

                <li>
                  <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                  <span>
                    <strong>Depuis le : { this.props.retour.dateEvt }</strong>
                  </span>
                </li>
                
                  { this.props.retour.texte ?
                    <li><span>Informations complementaires :  {this.props.retour.texte} </span></li>
                  :
                      ''
                  }
                

              </ul>
            )
        }

        if (this.props.retour.couleurEtatSonde === 'vert'){
            return (
              <ul className="bubble mb-md">
                <li>
                  <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                  <span className="bubble__content">{ this.props.retour.nom } : <strong> OK </strong></span>
                </li>

                <li>
                  <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                  <span> Dernier evenement le : <strong> { this.props.retour.dateEvt } </strong></span>
                </li>

                <li>
                  { this.props.retour.texte ?
                  <span>Informations complementaires : {this.props.retour.texte} </span>
                  :
                      ''
                  }
                </li>
              </ul>
            )
        }

        // si ni rouge ni vert
        return (null)
    }
}
