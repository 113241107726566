import React, { Component } from "react";

export default class CardState extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    getTextFromState(color) {
        switch (color) {
            case "vert":
                return "Les conditions de pose sont bonnes";
            case "orange":
                return "Les conditions de pose sont moyennes";
            case "rouge":
                return "Les conditions de pose ne sont pas réunies. POSE NON RECOMMANDÉE.";
            default:
                return (
                    "Les conditions de pose ne sont pas identifiables : " +color
                );
        }
    }

    render() {
        const colorCss = this.props.releve.couleurEtatRadio;
        const type = this.props.type;

        return (
            <div className={`card card--border card--border-${colorCss}`}>
                <h1 className="primary"> Testeur {type}</h1>
                <p>
                    {type} : <strong>{this.props.token}</strong>
                </p>
                <h2 className={`${colorCss}`}>{this.getTextFromState(colorCss) }</h2>
            </div>
        );
    }
}
