import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/organism/Breadcrumb'

class TesteurLoraInconnu extends Component {

    constructor(props){
        super(props)
        this.state = {
            serial: this.props.location.state.serial,
            errors: [],
        }
    }
    
    render() {
        return (
            <Fragment>
                <Breadcrumb titre='Testeur LoRaWAN' ico={sessionStorage.getItem('icoMenu')} />
                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--alert ico--center"></div>
                    <h3 className="text-alert text-center ">{ this.state.serial } </h3>
                    <h3 className="vertical-center__item text-center text-alert mb-xxxl">Erreur : Le numéro de testeur loRaWAN { this.state.serial } saisi est inconnu.</h3>
             
                    <Link className="btn btn-primary text-center mt-xxxl" to="TesteurLoraSaisi">  Entrer un nouveau identifiant de testeur LoRaWAN </Link>

                </div>
            </Fragment>
        )
    }
}

export default TesteurLoraInconnu