import React, { Component , Fragment } from 'react'
import Breadcrumb from '../../components/organism/Breadcrumb'
import { Link } from 'react-router-dom'

export default class CngSimEnCours extends Component {

    constructor(props){
        super(props) 
        this.state = {
            serialCng:this.props.location.state.serialCng,
        }
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb titre='CNG - Activation SIM' ico='ico--sim' />

                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--sim ico--center mb-md"></div>

                    <h3 className="text-primary text-center ">La carte SIM liée au boitier { this.state.serialCng } est en cours d'activation.</h3>
                    <p className="text-center mb-xxxl">Elle sera activité automatiquement d’ici 48h au maximum. <br />
                        N'hésitez pas à venir contrôler régulièrement son évolution.
                    </p>
            
                    <Link className="btn btn-primary text-center mb-xxxl mt-xxxl" to="CngSimActivation"> Activer une autre carte SIM </Link>

                </div>
            </Fragment>
        )
    }
}