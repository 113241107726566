import React, { Component, Fragment } from 'react'


/*
couleur marqueur : { this.state.retourSigfox.couleurEtatSonde } , num : { this.state.retourSigfox.serial }, etat : { this.state.retourSigfox.recu ? 'recu' : 'non recu'} <br />
couleur marqueur : { this.state.retourSigfox.couleurEtatRadio }, radio : { this.state.retourSigfox.texteEtatRadio } <br />
dernier envoi : { this.state.retourSigfox.dernierEnvoi }
*/

export default class InfosSigfox extends Component {

    constructor(props){
        super(props)
        this.props = props
    }

    getIconFromText(color) {
        switch(color) {
          case 'vert':
            return 'bubble__ico--succes';
          case 'rouge':
            return 'bubble__ico--alert';
          case 'orange':
            return 'bubble__ico--warning';
          default:
            return null;
        }
      }

    render() {
        if (this.props.retour.recu === 'non'){
            return (
              <ul className="bubble">
                <li>
                    <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                    <span className="bubble__content"> Sigfox <strong>{ this.props.retour.serial }</strong> jamais recu</span>
                </li>
              </ul>
            )
        }
    
        return (
          <Fragment>
            <ul className="bubble">
              <li>
                  <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                  <span className="bubble__content"> Sigfox { this.props.retour.serial } recu le { this.props.retour.dernierEnvoi }</span>
              </li>
              <li>
                  <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatRadio)}`}></i>
                  <span> Etat radio : <strong>{ this.props.retour.texteEtatRadio }</strong></span>
              </li>
            </ul>
          </Fragment>
        )
    }
}
