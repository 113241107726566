import React, { Component } from 'react'

export default class Releve extends Component {
    constructor(props){
        super(props)
        this.props = props
    }

    getIconFromText(text) {
        switch(text) {
          case 'temperature':
            return 'ico--temperature';

          case 'hygrometrie':
            return 'ico--water';

          case 'pression':
            return 'ico--pression';

          case 'conductivite':
            return 'ico--conductivite';
            
          default:
            return null;
        }
      }

    render() {

      //"nom": "Sonde Température Ambiante",
      let cssAlarm = ""
      let textAlarm = ""
      if(this.props.retour.nom === "Sonde Température Ambiante" && this.props.retour.releve > 60) {
        cssAlarm = "rouge"
        textAlarm = "La température est excessive"
      }

        return (
            <div className="releve wrapper-90">
                <i className={`releve__ico ${this.getIconFromText(this.props.retour.type)}`}></i>
                <div className="releve__value">
                    <p className="primary"><strong>{ this.props.retour.nom }</strong></p>
                    <p>Relevé du : <strong>{this.props.retour.dateReleve}</strong></p>
                    <p className={cssAlarm}>{this.props.retour.type} : <strong>{this.props.retour.releve} {this.props.retour.unite}</strong> {textAlarm}</p>
                </div>
            </div>
        )
    }
}