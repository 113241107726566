import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

import Breadcrumb from '../../components/organism/Breadcrumb'


class TRT200RapportOK extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumb titre='TRT200' ico='ico--trt200' />
                <div className="vertical-center text-center">
                    <i className="ico__md ico--center ico--check-succes"></i>
                    <h3 className=" text-center text-succes mt-xxl mb-xxl"> Le rapport de pose de la <br />chambre {this.props.numChambre} a bien été envoyé. </h3>
                    <Link className="btn btn-primary mt-xxl" to="Home">Revenir à l'accueil</Link>
                </div>
            </Fragment>
        )
    }
}

export default TRT200RapportOK