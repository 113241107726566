import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../components/organism/Breadcrumb'
import axios from 'axios'

class SigfoxNonVu extends Component {

    constructor(props){
        super(props)
        this.state = {
            serial: this.props.location.state.token,
            errors: [],
        }
    }

    handleReload = event => {
        this.setState({active : true})

        const token = localStorage.getItem('token')
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };

        let bodyFormData = new FormData()
        bodyFormData.set('serial', this.state.serial)
        bodyFormData.set('menuElementId', sessionStorage.getItem('menu'))

        axios.post(process.env.REACT_APP_API_URL+'/Sigfox/detail', bodyFormData, config)
            .then(res => {
                if(res.data.data.trouve === true){
                    this.props.history.push({
                        pathname: '/SigfoxDetail',
                        state: { detail: res.data.data }
                    })
                }
                else{
                    this.setState({active : false})
                }
            })
            .catch(error =>{
                //console.log(error);
                // erreur 400 = mauvais format
                // erreur 403 = existe pas
                if (error.response.status === 400) {
                    this.setState({errors : error.response.data.errors})
                }
                if (error.response.status === 403) {
                    this.setState({chambreNonTrouvee : true})
                }
            }) 
    }
    
    render() {
        return (
            <Fragment>
                <Breadcrumb titre='Sigfox' ico={sessionStorage.getItem('icoMenu')} />
                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--code ico--center"></div>
                        <h3 className="text-alert text-center ">{ this.state.serial } </h3>
                    <p className="vertical-center__item text-center ">Au cours des dernières minutes, aucune communication n’a été reçue de la part du boitier.</p>
             
                    <Link className= "btn btn-primary text-center mt-xxxl mb-0 ico__animation" to="#" onClick={() => this.handleReload()}> <i className= "ico__sm ico--ib ico--reload " ></i> Tester à nouveau </Link>
                    <Link className="btn btn-primary text-center mb-xxxl" to="SigfoxSaisie">  Entrer un nouveau identifiant Sigfox </Link>
                    
                <div className="card">
                    <h5><i className="ico__sm ico--ib ico--tool pr-md"></i> Dépannage</h5>
                    <ul className="list-simple">
                        <li>Penser à vérifier qu’il n’y ai pas d’erreur de saisie pour l’identifiant.</li>
                        <li>Repasser l’aimant sur l’étiquette du boitier et attendez au moins 1 minute avant de recharger cette page.</li>
                        <li>Ne laisser pas l’aimant posé sur l’étiquette.</li>
                        <li>Réessayer en rapprochant le boitier d’une ouverture.</li>
                    </ul>
                </div>

                </div>
            </Fragment>
        )
    }
}

export default SigfoxNonVu