import React, { Component, Fragment } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';


class MainNav extends Component {
    constructor(){
        super()
        this.state = {
            token: null,
            elementsDuMenu: [],
            redirect: false,
            toggleClass: false
        }
    }

    componentDidMount(){

        const token = localStorage.getItem('token')
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };
        
        axios.get(process.env.REACT_APP_API_URL+'/menu', config)
            .then(res => {
                this.setState( {elementsDuMenu : res.data.data})
            })
            .catch(error =>{
                if (error.response.status === 401) {
                    this.setState({redirect : true})
                    //this.props.history.push('/')
                }
            })
    }

    logout = () => {
        localStorage.setItem('token', null)
        localStorage.clear()
        sessionStorage.clear()
        this.setState({ redirect: true})
    }

    handleClick(element){
        // sessionStorage car c'est a l'onglet
        sessionStorage.setItem('menu', element.id)
        sessionStorage.setItem('icoMenu', element.icon)
        this.props.history.push('/'+element.link);
    }
 
    toggle() {
        this.setState({toggleClass: !this.state.toggleClass});
    }

    render() {

        // redirect if not identified
        if(this.state.redirect) {
            return (<Redirect to="Login" />)
        }

        // toggle nav class
        let navClass = ["main-nav__button"];
        if(this.state.toggleClass) {
            navClass.push('main-nav__button--active')
        }

        return (
            <Fragment>
                <nav className="main-nav">
                    <Link className={navClass.join(' ')} onClick={this.toggle.bind(this)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </Link>
                    <div className="main-nav__overlay">
                        <div className="container">
                            <ul className="main-nav__list">
                                <li key="dur0"><Link to="/Home"><span className="ico--ib ico__md mr-md ico--home"></span> Accueil</Link></li>
                                {
                                    this.state.elementsDuMenu.map((element) =>
                                        <li key={element.id}><Link to="#" onClick={() => this.handleClick(element)} ><span className={`ico--ib ico__md mr-md ${element.icon}`}>
                                        </span> {element.nom}</Link></li>
                                    )
                                }
                                {
                                    localStorage.getItem('token') ?
                                        <li key="disco"><Link to="#" onClick={() => this.logout()}><span className="ico--ib ico__md mr-md ico--login"></span> Deconnecter</Link></li>
                                    :
                                        <li key="login"><Link to="/"><span className="ico--ib ico__md mr-md ico--login"></span> Login</Link></li>
                                }
                                <li> <a href="https://form.asana.com?k=48vsw64MvDEZMaMInlB6jw&d=1149962635734953" target="_blank" rel="noreferrer"><span className="ico--ib ico__md mr-md ico--bug"></span> Signaler un bug</a></li>
                            </ul>
                        </div>  
                    </div>
                </nav>

            </Fragment>
 
        )
    }
}

export default withRouter(MainNav);
