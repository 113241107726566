import React, { Component, Fragment } from "react";
import Releve from "./components/SigfoxReleve";
import Breadcrumb from "../../components/organism/Breadcrumb";
import axios from "axios";
import BtnCircle from "../../components/atom/BtnCircle";
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";

class SigfoxDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            detail: this.props.location.state.detail,
            errors: []
        };
    }

    getIconFromText(color) {
        switch (color) {
            case "vert":
                return "bubble__ico--succes";
            case "rouge":
                return "bubble__ico--alert";
            case "orange":
                return "bubble__ico--warning";
            default:
                return null;
        }
    }

    async handleReload (event) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.detail.token);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/Sigfox/detail",
                bodyFormData,
                config
            )
            .then((res) => {
                document.getElementById("spinnerSigfoxReload").style.display = "none";
                if (res.data.data.trouve === true) {
                    this.setState({ detail: res.data.data });
                } else {
                    this.props.history.push({
                        pathname: "/SigfoxNonVu",
                        state: { token: res.data.data.token }
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
                // erreur 400 = mauvais format
                // erreur 403 = existe pas
                if (error.response.status === 400) {
                    this.setState({ errors: error.response.data.errors });
                }
                if (error.response.status === 403) {
                    this.setState({ chambreNonTrouvee: true });
                }
            });
    };

    render() {
        // Switch LineBatterie className
        const seuilBatterie = 2.9;
        let cssLineAlertBatterie = "";
        if (this.state.detail.batterie < seuilBatterie) {
            cssLineAlertBatterie = "text-bold text-alert";
        }

        return (
            <Fragment>
                <Breadcrumb titre="Sigfox" ico="ico--sigfox" />
                <Spinner spinnerId="spinnerSigfoxReload"></Spinner>

                <div className="card card--border">
                    <h1 className="primary">
                        Sigfox {this.state.detail.token}
                    </h1>
                    <p className="">
                        Le boitier est bien connecté au réseau Sigfox.
                    </p>
                    <ul className="bubble">
                        <li>
                            <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                            <span> Dernier envoi : <strong> {this.state.detail.dateReleve} </strong> </span>
                        </li>

                        <li className={cssLineAlertBatterie}>
                            <i className={`bubble__ico ${ this.state.detail.batterie < seuilBatterie ? "bubble__ico--alert" : "bubble__ico--succes" }`} ></i>
                            <span className="bubble__content">
                                Batterie :
                                <strong>{this.state.detail.batterie} V</strong>
                            </span>
                        </li>

                        <li>
                            <i
                                className={`bubble__ico ${this.getIconFromText(
                                    this.state.detail.etatReseau.couleur
                                )}`}
                            ></i>
                            <span className="bubble__content">
                                Nombre de stations : {this.state.detail.nbStations}
                            </span>
                        </li>

                        <li>
                            <i
                                className={`bubble__ico ${this.getIconFromText(
                                    this.state.detail.etatReseau.couleur
                                )}`}
                            ></i>
                            <span className="bubble__content">
                                {" "}
                                Etat du reseau :{" "}
                                <strong>
                                    {" "}
                                    {this.state.detail.etatReseau.etat}
                                </strong>{" "}
                            </span>
                        </li>
                    </ul>
                </div>

                <div className="push-bottom">
                    <h1>Valeurs relevées</h1>
                    {this.state.detail.indexs.map((valeur, keyReleve) => (
                        <Releve
                            key={keyReleve}
                            retour={valeur}
                            type="eau"
                            dateReleve={this.state.detail.dateReleve}
                            num={keyReleve}
                        />
                    ))}
                    {this.state.detail.temps.map((valeur, keyReleve) => (
                        <Releve
                            key={keyReleve}
                            retour={valeur}
                            type="temperature"
                            dateReleve={this.state.detail.dateReleve}
                            num={keyReleve}
                        />
                    ))}
                </div>

                <div className="wrapper-100">
                    <div className="nav-bottom"> 
                        <div className="container btn-circle__container btn-circle__container--centered ">
                            {
                                <BtnCircle btnCss="btn-circle--reload">
                                    <Link
                                        to="#"
                                        onClick={() => {this.handleReload()
                                            document.getElementById("spinnerSigfoxReload").style.display = "block";
                                            this.handleReload();
                                        }}
                                    >
                                        Rafraîchir
                                    </Link>
                                </BtnCircle>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SigfoxDetail;
