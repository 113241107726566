import React from "react";

const Btn = (props) => {
    const btnCss = `btn ${props.btnCss} ${props.typeBtn}`;
    return <button 
        className={btnCss}
        onClick={props.clic}
        >
        {props.children}
    </button>
};

export default Btn