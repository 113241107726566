import React from 'react'
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

export class MapContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            venues: [
                { latitude: this.props.lat, longitude: this.props.lng }
            ]
        }
    }

    displayMarkers = () => {
        return this.state.venues.map((venue, index) => {
          return <Marker key={index} id={index} position={{
            lat: venue.latitude,
            lng: venue.longitude
          }}
          onClick={() => console.log(this.state.venues)} />
        })
      }

      render() {
        return (
          <Map
           google={this.props.google}
           zoom={Number(this.props.zoom) }
           initialCenter={{ lat: this.props.lat, lng: this.props.lng }}
          >
            {this.displayMarkers()}

          </Map>
        );
      }
    }


export default GoogleApiWrapper({
    apiKey:'AIzaSyDmCE0AdggcowfihZCe0nlHclK6SZDxkZQ'
  })(MapContainer);