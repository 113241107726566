import React, { } from 'react'

const Spinner = (props) => {

    const spinnerId = props.spinnerId;
    return (
            <div className="spinner-container" id={spinnerId} style={{ display: "none" }}>
                <div className="spinner-content">
                    <div className="spinner-picto">
                        <span></span>
                        <span></span> 
                        <span></span>
                        <span></span>
                        <span></span>   
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>   
                    </div>
                </div>
                <h1 className="spinner-text">En cours de <br />chargement</h1>
            </div>
    )
};

export default Spinner;

// https://stackoverflow.com/questions/37764665/typescript-sleep

// You have to wait for TypeScript 2.0 with async/await for ES5 support as it now supported only for TS to ES6 compilation.
// You would be able to create delay function with async:
// function delay(ms: number) { return new Promise( resolve => setTimeout(resolve, ms) ); }
// And call it
// await delay(300);
// Please note, that you can use await only inside async function.
// If you can't (let's say you are building nodejs application), just place your code in the anonymous async function. Here is an example:
// (async () => { // Do something before delay console.log('before delay') await delay(1000); // Do something after console.log('after delay') })();
// Example TS Application: https://github.com/v-andrew/ts-template