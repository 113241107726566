import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../components/organism/Breadcrumb'
import { Link } from 'react-router-dom'

export default class LoraReveil extends Component {

    render() {
        return (
            <Fragment>
                <Breadcrumb titre='LoRaWAN' ico='ico--lora' />
                <div className="vertical-center text-center wrapper-90">
                    <div className="ico__xl ico--compass ico--center"></div>
                    <p className="vertical-center__item text-center mb-xxl">Veuillez réveiller* le boitier en passant l’aimant sur ses cotés non ventilées.</p>
                    <Link className="btn btn-primary mt-xxl" to="LoraSaisie">Suivant</Link>
                    <blockquote>
                        *Le réveil et l’envoi des informations peut prendre jusqu’à 2 minutes. 
                        <br/>
                        Lorsque le réveil est en cours, la diode est rouge puis passe au vert lorsque le boitier est reconnu par le réseau. 
                    </blockquote>
                </div>
            </Fragment>
        )
    }
}