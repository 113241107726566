import React, { Component, Fragment } from "react";
import Releve from "./components/LoraReleve";
import Breadcrumb from "../../components/organism/Breadcrumb";
import Spinner from "../../components/Spinner";
import axios from "axios";
import BtnCircle from "../../components/atom/BtnCircle";
import { Link } from "react-router-dom";

class LoraDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            detail: this.props.location.state.detail,
            serial: this.props.location.state.token,
            errors: []
        };
    }

    getIconFromText(color) {
        switch (color) {
            case "vert":
                return "bubble__ico--succes";
            case "rouge":
                return "bubble__ico--alert";
            case "orange":
                return "bubble__ico--warning";
            default:
                return null;
        }
    }

    async handleReload(event) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/loRa/detail",
                bodyFormData,
                config
            )
            .then((res) => {
                document.getElementById("spinnerLoraReload").style.display = "none";
                if (res.data.data.releves === true) {
                    this.setState({ detail: res.data.data });
                } else {
                    this.props.history.push({
                        pathname: "/LoraNonVu",
                        state: { token: res.data.data.token }
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/LoraNonVu",
                    state: {
                        token: this.state.serial
                    }
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="LoRaWAN" ico="ico--lora" />
                <Spinner spinnerId="spinnerLoraReload"></Spinner>


                <div className="card card--border">
                    <h1 className="primary">
                        N° de série : {this.state.serial}
                    </h1>
                    <p className="">
                        Le boitier est bien connecté au réseau LoRa.
                    </p>
                    <ul className="bubble">
                        <li> N° DEVEUI : <strong>{this.state.detail.adresseRadio}</strong>  </li>
                        <li>
                            <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                            <span>
                                {" "}
                                Dernier envoi :{" "}
                                <strong>
                                    {" "}
                                    {this.state.detail.dateReleve}{" "}
                                </strong>
                            </span>
                        </li>

                        <li>
                            <i
                                className={`bubble__ico ${this.getIconFromText(
                                    this.state.detail.couleurRadio
                                )}`}
                            ></i>
                            <span className="bubble__content">
                                {" "}
                                Nombre de stations :{" "}
                                {this.state.detail.nbStations}
                            </span>
                        </li>

                        <li>
                            <i
                                className={`bubble__ico ${this.getIconFromText(
                                    this.state.detail.couleurRadio
                                )}`}
                            ></i>
                            <span className="bubble__content">
                                {" "}
                                Etat du reseau :{" "}
                                <strong>
                                    {" "}
                                    {this.state.detail.texteRadio}
                                </strong>{" "}
                            </span>
                        </li>
                    </ul>
                </div>

                <div className="push-bottom">
                    <h1>Valeurs relevées</h1>

                    <Releve
                        retour={this.state.detail.temp}
                        type="temperature"
                        dateReleve={this.state.detail.temp.date_releve}
                    />

                    <Releve
                        retour={this.state.detail.hygro}
                        type="hygrometrie"
                        dateReleve={this.state.detail.temp.date_releve}
                    />
                </div>

                <div className="wrapper-100">
                    <div className="nav-bottom">
                        <div className="container btn-circle__container">
                            {
                                <BtnCircle btnCss="btn-circle--reload">
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            document.getElementById(
                                                "spinnerLoraReload"
                                            ).style.display = "block";
                                            this.handleReload();
                                        }}
                                    >
                                        Rafraîchir
                                    </Link>
                                </BtnCircle>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LoraDetail;
