import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/organism/Breadcrumb";
import BtnCircle from "../../components/atom/BtnCircle";
import Spinner from "../../components/Spinner";


import InfosSigfox from "./components/TRT200InfosSigfox";
import InfosLoRa from "./components/TRT200InfosLoRa";
import InfosGPRS from "./components/TRT200InfosGPRS";

import AlarmeTRT200 from "./TRT200Alarme";
import Releve from "./components/TRT200Releve";

class TRT200Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numChambre: this.props.location.state.numChambre,
            refresh: false,
            errors: [],
            retourData: [],
            retourLoRa: [],
            retourSigfox: [],
            retourGPRS: [],
            retourCentrale: [],
            retourAlarmes: [],                      
            retourSondes: []
        };
    }

    componentDidMount() {
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        document.getElementById("spinnerTRT200Reload").style.display = "block";

        let bodyFormData = new FormData();
        bodyFormData.set("numChambre", this.state.numChambre);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/TRT200/detail",
                bodyFormData,
                config
            )
            .then((res) => {
                document.getElementById("spinnerTRT200Reload").style.display = "none";
                if (res.data.data.trouve === false) {
                    this.props.history.push({
                        pathname: "/TRT200ChambreKO"
                    });
                } else {
                    this.setState({ retourData: res.data.data });
                    this.setState({ retourLoRa: res.data.data.Lora });
                    this.setState({ retourSigfox: res.data.data.sigfox });
                    this.setState({ retourGPRS: res.data.data.gprs });
                    this.setState({ retourCentrale: res.data.data.centrale });
                    this.setState({ retourAlarmes: res.data.data.alarmes });
                    this.setState({ retourSondes: res.data.data.sondes });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.setState({ redirect: true });
                    //this.props.history.push('/')
                }
            });
    }

    async handleReload(event) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        this.componentDidMount();
    };

    handleRapport = (event) => {
        //console.log(this.state.numChambre);
        this.props.history.push({
            state: {
                numChambre: this.state.numChambre
            },
            pathname: "/TRT200Rapport"
        });
    };

    render() {
        const retourData = this.state.retourData;

        const seuilPeltier = 2.4;
        const seuilSecteur = 4;
        const seuilBatterie = 2.9;

        // Switch Secteur className
        let cssSecteur = "";
        let textSecteur = "";
        if (this.state.retourCentrale.voltageSecteur === false) {
            cssSecteur = "bubble__ico--warning";
            textSecteur = "chambre non électrifiée";
        } else {
            textSecteur = "V";
            if (this.state.retourCentrale.voltageSecteur < seuilSecteur) {
                cssSecteur = "bubble__ico--alert";
            } else {
                cssSecteur = "bubble__ico--succes";
            }
        }

        // Switch LinePeltier className
        let cssLineAlertPeltier = "";
        if (this.state.retourCentrale.voltagePeltier < seuilPeltier) {
            cssLineAlertPeltier = "text-bold text-alert";
        }

        // Switch LineBatterie className
        let cssLineAlertBatterie = "";
        if (this.state.retourCentrale.voltageBatterie < seuilBatterie) {
            cssLineAlertBatterie = "text-bold text-alert";
        }

        return (
            <Fragment>
                <Breadcrumb titre="TRT200" ico="ico--trt200" />
                <Spinner spinnerId="spinnerTRT200Reload"></Spinner>

                <div className="card card--border">
                    <h1 className="primary">Chambre {retourData.nomChambre}</h1>
                    <p> {retourData.adresse} </p>
                    <h2 className="primary mt-md">Gateway </h2>
                    <p className="mt-xs">
                        S/N : <strong>{retourData.serialCentrale}</strong>
                    </p>
                </div>

                <div className="card card--border">
                    <h1 className="primary">Alimentations</h1>
                    <ul className="bubble mt-sm">
                        <li>
                            Alimentations le :{" "}
                            <strong>
                                {this.state.retourCentrale.dateVoltages}
                            </strong>
                        </li>
                        <li className={cssLineAlertBatterie}>
                            <i
                                className={`bubble__ico ${
                                    this.state.retourCentrale.voltageBatterie <
                                    seuilBatterie
                                        ? "bubble__ico--alert"
                                        : "bubble__ico--succes"
                                }`}
                            ></i>
                            <span className="bubble__content">
                                Batterie :{" "}
                                <strong>
                                    {this.state.retourCentrale.voltageBatterie}{" "}
                                    V
                                </strong>
                            </span>{" "}
                        </li>
                        <li>
                            <i className={`bubble__ico ${cssSecteur}`}></i>
                            <span className="bubble__content">
                                {" "}
                                Secteur :{" "}
                                <strong>
                                    {this.state.retourCentrale.voltageSecteur}{" "}
                                    {textSecteur}
                                </strong>
                            </span>
                        </li>
                        <li className={cssLineAlertPeltier}>
                            <i
                                className={`bubble__ico ${
                                    this.state.retourCentrale.voltagePeltier <
                                    seuilPeltier
                                        ? "bubble__ico--alert"
                                        : "bubble__ico--succes"
                                }`}
                            ></i>
                            <span className="bubble__content">
                                {" "}
                                Peltier :{" "}
                                <strong>
                                    {this.state.retourCentrale.voltagePeltier} V
                                </strong>
                            </span>
                        </li>
                    </ul>
                </div>

                <h3 className="wrapper-90">Etats des communications radio</h3>

                <div className="card">
                    <h1>Sigfox</h1>
                    <InfosSigfox retour={this.state.retourSigfox} />
                </div>

                <div className="card">
                    <h1>LoRa</h1>
                    <InfosLoRa retour={this.state.retourLoRa} />
                </div>

                <div className="card">
                    <h1>GPRS</h1>
                    <InfosGPRS retour={this.state.retourGPRS} />
                </div>

                <div className="card">
                    <h3>
                        <span className="ico-sm ico--alarm mr-sm"></span>Alarmes
                    </h3>
                    {this.state.retourAlarmes.map((alarme, keyAlarm) => (
                        <AlarmeTRT200 key={keyAlarm} retour={alarme} />
                    ))}
                </div>

                <div className="push-bottom">
                    <h1>Valeurs relevées</h1>
                    {this.state.retourSondes.map((sonde, keyReleve) => (
                        <Releve key={keyReleve} retour={sonde} />
                    ))}
                </div>

                <div className="wrapper-100">
                    <div className="nav-bottom">
                        <div className="container btn-circle__container">
                            <BtnCircle btnCss="btn-circle--reload">
                                <Link
                                    to="#"
                                    onClick={() => {
                                        document.getElementById(
                                            "spinnerTRT200Reload"
                                        ).style.display = "block";
                                        this.handleReload();
                                    }}
                                >
                                    Rafraîchir
                                </Link>
                            </BtnCircle>
                            <BtnCircle btnCss="btn-circle--report">
                                <Link
                                    to="#"
                                    onClick={() => this.handleRapport()}
                                >
                                    Rapport
                                </Link>
                            </BtnCircle>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default TRT200Detail;
