import React, { Component, Fragment } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/organism/Breadcrumb";
import BtnCircle from "../../components/atom/BtnCircle";

class TRT200Rapport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            refresh: false,
            numChambre: sessionStorage.getItem("numChambreTRT200"),
            message: "",
            images: [],
            miniatures: [],
            rapportGenere: "",
            rapportOk: "",
            errors: [],
            active: false
        };

        console.log(
            "les props : " + JSON.stringify(this.props, null, 2) + " fini"
        );
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({ active: true });
        const token = localStorage.getItem("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "multipart/form-data"
            }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("message", this.state.message);
        //bodyFormData.set('images[]', this.state.images)

        const files = this.state.images;

        for (let i = 0; i < files.length; i++) {
            bodyFormData.append(`images[${i}]`, files[i]);
        }

        //bodyFormData.append('images', this.state.images)
        bodyFormData.set("numChambre", sessionStorage.getItem("numChambreTRT200"));
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/TRT200/rapport",
                bodyFormData,
                config
            )
            .then((res) => {
                if (res.data.data.trouve === true) {
                    this.props.history.push({
                        pathname: "/TRT200RapportOK"
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/TRT200RapportKO",
                    state: {
                        errors: error.response.data.errors.toString()
                    }
                });
            });
    };

    handleMessageChange = (event) => {
        this.setState({ message: event.target.value });
    };

    handleImagesChange = (event) => {
        console.clear();
        this.setState({ miniatures: [] });
        /*
        var fichiers = event.target.files;
        var nbFichiers = fichiers.length;
        var i = 0;
        var tabImages = [];
        while(i < nbFichiers){
            tabImages[i] = fichiers[i];
            i++;
        }
        this.setState({ images : tabImages })   
        */
        this.setState({ images: event.target.files });
        if (event.target.files && event.target.files[0]) {
            for (let file of event.target.files) {
                this.setState((prevState) => ({
                    miniatures: [
                        ...prevState.miniatures,
                        URL.createObjectURL(file)
                    ]
                }));
            }
        }
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="TRT200" ico="ico--trt200" />
                <h3 className="text-center mt-xxl">
                    Génération du rapport de pose{" "}
                </h3>
                <h1 className="text-center text-primary mb-xxl">
                    Chambre {this.state.numChambre}
                </h1>
                <form method="POST" onSubmit={this.handleSubmit}>
                    <label
                        className="pb-2 text-left"
                        htmlFor="message"
                        id="label-message"
                    >
                        {" "}
                        <i className="ico__sm ico--ib ico--comment pr-md"></i>{" "}
                        Avant de valider ce rapport, voulez-vous laisser un
                        commentaire ou signaler quelque chose ?{" "}
                    </label>
                    <textarea
                        rows="3"
                        id="message"
                        onChange={this.handleMessageChange}
                        placeholder="Laisser un commentaire"
                    ></textarea>
                    <p className="mt-md">
                        <i className="ico__sm ico--ib ico--photo pr-md"></i>{" "}
                        Voulez envoyer une ou plusieurs photos ?
                    </p>
                    <label
                        htmlFor="images"
                        className="custom-file-upload"
                    ></label>
                    <input
                        type="file"
                        id="images"
                        accept="image/*"
                        multiple
                        onChange={this.handleImagesChange}
                    />

                    {this.state.errors.images ? (
                        <div> {this.state.errors.images}</div>
                    ) : (
                        ""
                    )}

                    <div className="wrapper-100">
                        <div className="nav-bottom">
                            <div className="container btn-circle__container btn-circle__container--centered ">
                                <BtnCircle btnCss="btn-circle--send">
                                    <Link
                                        className={` ${
                                            this.state.active ? "active" : ""
                                        }`}
                                        type="submit"
                                        id="btnSendReport"
                                        onClick={this.handleSubmit}
                                        to="#"
                                    >
                                        {" "}
                                        Envoyer
                                    </Link>
                                </BtnCircle>
                            </div>
                        </div>
                    </div>
                </form>
                <div>
                    {this.state.miniatures.map((image, key) => (
                        <img
                            className="vignette"
                            key={key}
                            alt=""
                            src={image}
                        />
                    ))}
                </div>
            </Fragment>
        );
    }
}

export default TRT200Rapport;
