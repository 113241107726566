import React, { Component, Fragment } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import axios from 'axios'
import LogoLogin from '../../components/atom/LogoLogin'
// import txt from '../global/global_fr.json'

class Home extends Component {
    constructor(){
        super()
        this.state = {
            token: null,
            elementsDuMenu: [],
            redirect: false
        }
    }

    componentDidMount(){

        const token = localStorage.getItem('token')
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }
        };
        
        axios.get(process.env.REACT_APP_API_URL+'/menu', config)
            .then(res => {
                this.setState( {elementsDuMenu : res.data.data})
            })
            .catch(error =>{
                localStorage.setItem('token', null)
                localStorage.clear()
                sessionStorage.clear()
                this.setState({ redirect: true})
            })
    }

    logout = () => {
        localStorage.setItem('token', null)
        localStorage.clear()
        sessionStorage.clear()
        this.setState({ redirect: true})
    }

    handleClick(element){
        // sessionStorage car c'est a l'onglet
        sessionStorage.setItem('menu', element.id)
        sessionStorage.setItem('icoMenu', element.icon)
        this.props.history.push('/'+element.link);
    }
 
    render() {
        if(this.state.redirect === true){
            return (<Redirect to="Login" />)
        }

        return (

            <Fragment>
                <LogoLogin></LogoLogin>
                <div className="vertical-bottom vertical-bottom--auto wrapper-90">

                    <p className="mt-xxl text-center mb-xxl mt-xxl">Sélectionner l’outil que vous voulez utiliser.</p>

                    {
                        this.state.elementsDuMenu.map((element) =>
                            <div key={element.id}>
                                <Link className="card card__nav" to="#" onClick={() => this.handleClick(element)} >
                                    <div className="card-body"> <span className={`ico--ib ico__md mr-md ${element.icon}`}></span>{element.nom}</div>
                                </Link>
                            </div>
                        )
                    }

                    {
                        localStorage.getItem('token') ?
                            <div key="disco"><Link className="card card__nav" to="#" onClick={() => this.logout()}><div className="card-body"><span className="ico--ib ico__md mr-md ico--login"></span>Se déconnecter</div></Link></div>
                        :
                            <div key="login"><Link className="card card__nav" to="/"><div className="card-body"><span className="ico--ib ico__md mr-md ico--login"></span>Se connecter</div></Link></div>
                    }

                </div>
            </Fragment>

        )
    }
}


export default withRouter(Home)