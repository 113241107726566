import React, { Component, Fragment } from "react";
import axios from "axios";

import Btn from "../../components/atom/Btn";
import Breadcrumb from "../../components/organism/Breadcrumb";

class TRT200 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numChambre: "",
            errors: [],
            retourData: []
        };
    }

    handleNumChambreChange = (event) => {
        this.setState({ numChambre: event.target.value });
    };

    handleSubmitChambre = (event) => {
        event.preventDefault();

        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("numChambre", this.state.numChambre);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/TRT200/rechercher",
                bodyFormData,
                config
            )
            .then((res) => {
                if (res.data.data.trouve === false) {
                    this.props.history.push({
                        pathname: "/TRT200ChambreKO"
                    });
                } else {
                    sessionStorage.setItem(
                        "numChambreTRT200",
                        this.state.numChambre
                    );
                    this.props.history.push({
                        pathname: "/TRT200SaisieSerial",
                        state: {
                            retourData: res.data.data,
                            numChambre: this.state.numChambre
                        }
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
                // erreur 400 = mauvais format
                // erreur 403 = existe pas
                if (error.response.status === 400) {
                    this.setState({ errors: error.response.data.errors });
                }
                if (error.response.status === 403) {
                    this.props.history.push({
                        pathname: "/TRT200ChambreKO"
                    });
                }
            });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb
                    titre="TRT200"
                    ico={sessionStorage.getItem("icoMenu")}
                />
                <div className="vertical-center">
                    <div className="ico__xl ico--location ico--center"></div>

                    <p className="vertical-center__item text-center">
                        Entrer le numéro de la chambre que vous voulez
                        contrôler.
                    </p>

                    <form
                        className="vertical-center__item"
                        method="POST"
                        onSubmit={this.handleSubmitChambre}
                    >
                        <input
                            type="number"
                            id="numChambre"
                            onChange={this.handleNumChambreChange}
                            placeholder="Numéro de chambre"
                        />
                        {this.state.errors.numChambre ? (
                            <div> {this.state.errors.numChambre}</div>
                        ) : (
                            ""
                        )}

                        <Btn
                            btnCss="btn btn-primary mt-md"
                            type="submit"
                            id="submit"
                        >
                            Rechercher
                        </Btn>
                    </form>
                </div>
            </Fragment>
        );
    }
}

export default TRT200;
