import React, { Component, Fragment } from "react";
import Breadcrumb from "../../components/organism/Breadcrumb";
import { Link } from "react-router-dom";

export default class CngSimActive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serialCng: this.props.location.state.serialCng
        };
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb titre="CNG - Activation SIM" ico="ico--sim" />

                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--sim ico--center mb-md"></div>

                    <h3 className="text-succes text-center ">
                        La carte SIM liée au boitier {this.state.serialCng} est
                        déjà activée.
                    </h3>
                    <p className="text-center mb-xxxl">
                        Elle est actuellement utilisable.
                    </p>

                    <Link
                        className="btn btn-primary text-center mb-xxxl mt-xxxl"
                        to="CngSimActivation"
                    >
                        {" "}
                        Activer une autre carte SIM{" "}
                    </Link>
                </div>
            </Fragment>
        );
    }
}
