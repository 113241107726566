import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/organism/Breadcrumb";

class TRT200RapportKO extends Component {
    render() {
        return (
            <Fragment>
                <Breadcrumb titre="TRT200" ico="ico--trt200" />
                <div className="vertical-center text-center">
                    <i className="ico__md ico--center ico--alert-alert"></i>
                    <h3 className=" text-center text-alert mt-xxl mb-xxl">
                        {" "}
                        Echec lors de l'envoi du rapport. <br />
                        Erreurs : {this.props.location.state.errors}
                    </h3>
                    <Link className="btn btn-primary mt-xxl" to="Home">
                        Revenir à l'accueil
                    </Link>
                </div>
            </Fragment>
        );
    }
}

export default TRT200RapportKO;
