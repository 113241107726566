import React from "react";

const BtnCircle = (props) => {
    const btnCss = `btn-circle ${props.btnCss} ${props.typeBtn}`;
    return <div 
        className={btnCss} 
        >

        <p className="text-center"> {props.children}</p>
    </div>
};

export default BtnCircle