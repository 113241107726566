import React, { Component, Fragment } from "react";
import Btn from "../../components/atom/Btn";
import axios from "axios";
import Breadcrumb from "../../components/organism/Breadcrumb";

export default class SigfoxSaisie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serial: "",
            sigfoxCherche: "",
            sigfoxTrouve: "",
            errors: [],
            retourData: []
        };
    }

    handleSerialChange = (event) => {
        this.setState({ serial: event.target.value.toUpperCase() });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/Sigfox/detail",
                bodyFormData,
                config
            )
            .then((res) => {
                if (res.data.data.trouve === true) {
                    this.props.history.push({
                        pathname: "/SigfoxDetail",
                        state: { detail: res.data.data }
                    });
                } else {
                    this.props.history.push({
                        pathname: "/SigfoxNonVu",
                        state: { token: res.data.data.token }
                    });
                }
            })
            .catch((error) => {
                //console.log(error);
                // erreur 400 = mauvais format
                // erreur 403 = existe pas
                if (error.response.status === 400) {
                    this.setState({ errors: error.response.data.errors });
                }
                if (error.response.status === 403) {
                    this.setState({ chambreNonTrouvee: true });
                }
            });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb
                    titre="Sigfox"
                    ico={sessionStorage.getItem("icoMenu")}
                />
                <div className="vertical-center text-center wrapper-90">
                    <div className="ico__xl ico--code ico--center"></div>

                    <p className="vertical-center__item">
                        Entrer l’identifiant Sigfox* du boitier que vous voulez
                        installer.
                    </p>

                    <form
                        className="vertical-center__item"
                        method="POST"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            type="text"
                            id="serial"
                            onChange={this.handleSerialChange}
                            placeholder="Identifiant Sigfox"
                        />
                        {this.state.errors.serial ? (
                            <div> {this.state.errors.serial}</div>
                        ) : (
                            ""
                        )}

                        <Btn
                            btnCss="btn btn-primary mt-md"
                            type="submit"
                            id="submit"
                        >
                            Rechercher
                        </Btn>
                    </form>

                    <blockquote>
                        *L’identifiant Sigfox se trouve sur l’étiquette du
                        boitier. Ne pas confondre avec le S/N. *Il se compose de
                        maximum 8 caractères : chiffres ou lettres de A à F. Il
                        ne faut pas d’espace.
                    </blockquote>
                </div>
            </Fragment>
        );
    }
}
