import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../components/organism/Breadcrumb'

export default class CngSimErreur extends Component {

    constructor(props){
        super(props)
        this.state = {
            serialCng: this.props.location.state.serialCng,
            errorMessage: this.props.location.state.errorMessage,
        }
    }

    
    render() {

        return (
            <Fragment>
                <Breadcrumb titre='CNG - Activation SIM' ico='ico--sim' />

                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--sim ico--center mb-md"></div>

                    <h3 className="text-alert text-center ">L'activation de la centrale { this.state.serialCng } n'est pas possible.</h3>
                    <p className="text-center mb-xxxl">{this.state.errorMessage}</p>
            
                    <Link className="btn btn-primary text-center mb-xxxl mt-xxxl" to="CngSimActivation">Essayer un autre numéro de centrale </Link>

                </div>
            </Fragment>

        )
    }
}
