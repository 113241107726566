import React, { Component } from "react";

export default class LeakageReleve extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
                <>
                    <div className="card">
                        <ul className="bubble mt-0">
                            <li><i className=" ico--ib ico__sm sm ico--temperature mr-xs"></i>Température : <strong>{this.props.temperature} °C</strong></li>
                            <li><i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>Relevé du : <strong>{this.props.dateReleve}</strong></li>
                        </ul>
                    </div>

                    <div className="card">
                        <ul className="bubble mt-0">
                            <li>
                                {this.props.debutAlarmeSonde1 ? 
                                    <>
                                        <i className="bubble__ico bubble__ico--alert"></i>
                                        <span className="bubble__content"> Niveau d'eau bas : <strong> présence d'eau : </strong> <br/> depuis : {this.props.debutAlarmeSonde1}</span>
                                    </>
                                :
                                    <>
                                        <i className="bubble__ico bubble__ico--succes"></i>
                                        <span className="bubble__content"> Niveau d'eau bas : <strong> absence d'eau </strong></span>
                                    </>
                                }
                            </li>
                            <li>
                                {this.props.debutAlarmeSonde2 ?
                                    <>
                                        <i className="bubble__ico bubble__ico--alert"></i>
                                        <span className="bubble__content"> Niveau d'eau haut : <strong> présence d'eau : </strong> <br/> depuis : {this.props.debutAlarmeSonde2}</span>
                                    </>
                                :
                                    <>
                                        <i className="bubble__ico bubble__ico--succes"></i>
                                        <span className="bubble__content"> Niveau d'eau haut : <strong> absence d'eau </strong></span>
                                    </>
                                }
                            </li>
                        </ul>
                    </div>
                </>
        );
    }
}
