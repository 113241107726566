import React, { Component } from 'react'
import BtnBack from '../atom/BtnBack'
import MainNav from '../molecule/MainNav'

class Breadcrumb extends Component {
    constructor(props) {
        super(props)
        this.titre = props.titre
        this.ico = props.ico
    }
    
    render() {
        return (
            <div className="breadcrumb">
                <div className="breadcrumb__left">
                    <BtnBack />
                </div>

                <div className="breadcrumb__right">
                    { this.titre }
                    <i className={ this.ico }></i>
                    <MainNav />
                </div>
            </div>
        )
    }
}

export default Breadcrumb