import React, { Component } from "react";

export default class Releve extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    getIconFromText(text) {
        switch (text) {
            case "temperature":
                return "ico--temperature";

            case "hygrometrie":
                return "ico--water";

            case "eau":
                return "ico--water";

            case "pression":
                return "ico--pression";

            case "conductivite":
                return "ico--conductivite";

            default:
                return null;
        }
    }

    render() {
        return (
            <div className="releve wrapper-90">
                <i
                    className={`releve__ico ${this.getIconFromText(
                        this.props.type
                    )}`}
                ></i>
                <div className="releve__value">
                    <p className="primary">
                        <strong>Sonde {this.props.type}</strong>
                    </p>
                    <p>
                        Relevé du : <strong>{this.props.dateReleve}</strong>
                    </p>
                    <p>
                        {this.props.type} :{" "}
                        <strong>
                            {this.props.retour.valeur} {this.props.retour.unite}
                        </strong>
                    </p>
                </div>
            </div>
        );
    }
}
