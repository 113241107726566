import './styles/sass/app.scss';

import { BrowserRouter as Router, } from "react-router-dom";
import AppRouter from './pages/AppRouter';

function App() {
  return (
    <main className="container">
      <Router>
          <AppRouter />
      </Router>
    </main>

  );
}

export default App;
