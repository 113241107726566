import React, { Component, Fragment } from "react";
import Breadcrumb from "../../components/organism/Breadcrumb";
import Btn from "../../components/atom/Btn";
import axios from "axios";

export default class CngSimActivation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serialCng: "",
            errors: []
        };
    }

    handleSerialChange = (event) => {
        this.setState({ serialCng: event.target.value.toUpperCase() });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        // on le laisse vide en fait
        let bodyFormData = new FormData();

        axios
            .post(
                process.env.REACT_APP_API_URL +
                    "/matooma/pairSimBySerial/" +
                    this.state.serialCng,
                bodyFormData,
                config
            )
            .then((res) => {
                // cas ou la centrale est inconnue
                if (res.data.data.erreurActivation === true) {
                    this.props.history.push({
                        pathname: "/CngSimErreur",
                        state: {
                            serialCng: this.state.serialCng,
                            errorMessage: res.data.data.erreurActivationTxt
                        }
                    });
                }

                // cas ou la carte sim est deja activee
                if (res.data.data.dejaActive === true) {
                    this.props.history.push({
                        pathname: "/CngSimActive",
                        state: { serialCng: this.state.serialCng }
                    });
                }

                // cas ou l'activation de la carte sim a ete demandee avant
                if (res.data.data.activationDejaDemandee === true) {
                    this.props.history.push({
                        pathname: "/CngSimDejaDemandee",
                        state: {
                            serialCng: this.state.serialCng,
                            informations: res.data.data.etatDemandePrecedente
                        }
                    });
                }

                // cas ou la centrale est en cours d activation
                if (res.data.data.activationDemandee === true) {
                    this.props.history.push({
                        pathname: "/CngSimEnCours",
                        state: { serialCng: this.state.serialCng }
                    });
                }

                // erreur sais ie forma serial
                if (res.data.data.error === true) {
                    this.setState({ errors: res.data.data.errors[0] });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/CngSimErreur",
                    state: {
                        serialCng: this.state.serialCng,
                        errorMessage: error.response.data.errors
                    }
                });
            });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="CNG - Activation SIM" ico="ico--sim" />

                <div className="vertical-center text-center wrapper-90">
                    <div className="ico__xl ico--code ico--center"></div>

                    <p className="vertical-center__item">
                        Rentrer le numéro de série de la centrale.
                    </p>

                    <form
                        className="vertical-center__item"
                        method="POST"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            type="text"
                            id="serial"
                            onChange={this.handleSerialChange}
                            placeholder="N° de série de la centrale"
                        />
                        {this.state.errors ? (
                            <p className="text-alert"> {this.state.errors}</p>
                        ) : (
                            ""
                        )}

                        <Btn
                            btnCss="btn btn-primary mt-md"
                            type="submit"
                            id="submit"
                        >
                            Rechercher
                        </Btn>
                    </form>

                    <blockquote>
                        * Le numéro de série de la centrale se trouve sur
                        l’étiquette du boitier. <br />
                        *Il se compose de maximum 8 caractères : chiffres ou
                        lettres de A à F. Il ne faut pas d’espace, ni de 0
                        devant.
                    </blockquote>
                </div>
            </Fragment>
        );
    }
}
