import React, { Component } from 'react'

/*
couleur marqueur : { this.state.retourGPRS.couleurEtatSonde } , num : { this.state.retourGPRS.simICCID }, etat : { this.state.retourGPRS.recu ? 'recu' : 'non recu'} <br />
dernier envoi : { this.state.retourGPRS.dernierEnvoi } <br />
*/


export default class InfosGPRS extends Component {

    constructor(props){
        super(props)
        this.props = props
    }

    getIconFromText(color) {
        switch(color) {
          case 'vert':
            return 'bubble__ico--succes';
          case 'rouge':
            return 'bubble__ico--alert';
          case 'orange':
            return 'bubble__ico--warning';
          default:
            return null;
        }
      }

    render() {
        if (this.props.retour.recu === 'non'){
            return (
              <ul className="bubble">
                <li>
                    <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                    <span className="bubble__content"> GPRS { this.props.retour.simICCID } : <strong>jamais recu</strong></span>
                </li>
              </ul>
            )
        }
    
        return (
            <ul className="bubble">
              <li>
                  <i className={`bubble__ico ${this.getIconFromText(this.props.retour.couleurEtatSonde)}`}></i>
                  <span className="bubble__content"> GPRS { this.props.retour.simICCID } : <strong>recu</strong> </span>
              </li>
              <li>
                  <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                  <span> Dernier envoi : <strong>{ this.props.retour.dernierEnvoi } </strong></span>
              </li>
            </ul>
        )
    }
}
