import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../components/organism/Breadcrumb";
import Spinner from "../../../components/Spinner";
import CardState from "../components/CardState";
import axios from "axios";
import BtnCircle from "../../../components/atom/BtnCircle";
import { Link } from "react-router-dom";

class TesteurLoraDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            detail: this.props.location.state.detail,
            serial: this.props.location.state.serial,
            errors: []
        };
    }

    getIconFromText(color) {
        switch (color) {
            case "vert":
                return "bubble__ico--succes";
            case "rouge":
                return "bubble__ico--alert";
            case "orange":
                return "bubble__ico--warning";
            default:
                return null;
        }
    }

    getTextFromState(color) {
        switch (color) {
            case "vert":
                return "Les conditions de pose sont bonnes";
            case "orange":
                return "Les conditions de pose sont moyennes";
            case "rouge":
                return "Les conditions de pose ne sont pas réunies. Pose non recommandé.";
            default:
                return (
                    "Les conditions de pose ne sont pas identifiables " +color
                );
        }
    }

    getReleveFromState(index = 0) {
        let releve = [];
        let detail = this.state.detail
        if ( detail !== undefined && detail.releves !== undefined && detail.releves[index] !== undefined) {
            releve = detail.releves[index];
        }
        return releve;
    }

    async handleReload(event) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/loRa/testeur",
                bodyFormData,
                config
            )
            .then((res) => {
                document.getElementById("spinnerTesteurReload").style.display =
                    "none";
                if (res.data.data.trouve === true) {
                    this.setState({ detail: res.data.data });
                } else {
                    this.props.history.push({
                        pathname: "/TesteurLoraNonVu",
                        state: { serial: res.data.data.serial }
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/TesteurLoraNonVu",
                    state: {
                        serial: this.state.serial
                    }
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="Testeur LoRaWAN" ico="ico--testeur" />
                <Spinner spinnerId="spinnerTesteurReload"></Spinner>

                <CardState
                    type="LoRaWAN"
                    token={this.state.detail.devEui}
                    releve={this.getReleveFromState()}
                ></CardState>

                <div className="push-bottom">
                    <h1>Etats des communications radio</h1>

                    {this.state.detail.releves.map((el) => (
                        <Fragment>
                            <div className="card">
                                <ul className="bubble mt-0">
                                    <li>
                                        <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                                        Envoi : <strong> {el.dateReleve}</strong>
                                    </li>

                                    <li>
                                        <i className={`bubble__ico ${this.getIconFromText(el.couleurEtatRadio)}`}></i>
                                        <span className="bubble__content">
                                            Etat : <strong>{el.texteEtatRadio} {el.nbStations} station(s)</strong>
                                        </span>
                                    </li>

                                </ul>
                            </div>
                        </Fragment>
                    ))}
                </div>

                <div className="wrapper-100">
                    <div className="nav-bottom">
                        <div className="container btn-circle__container btn-circle__container--centered">
                            {
                                <BtnCircle btnCss="btn-circle--reload">
                                    <Link to="#" onClick={() => { document.getElementById( "spinnerTesteurReload" ).style.display = "block"; this.handleReload(); }} >
                                        Rafraîchir
                                    </Link>
                                </BtnCircle>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default TesteurLoraDetail;
