import React, { Component } from 'react'

import axios from 'axios'
import { Redirect } from 'react-router-dom'
import LogoLogin from '../../components/atom/LogoLogin'
import Btn from '../../components/atom/Btn'

class Login extends Component {
    constructor(){
        super()
        this.state = {
            email: '',
            password: '',
            redirect: false,
            errors: []
        }
    }

    componentDidMount(){
        if (localStorage.getItem('token')) {
            this.setState({redirect: true})
        }
    }
    
    handleEmailChange = event => {
        this.setState({ email : event.target.value })   
    }
    handlePasswordChange = event => {
        this.setState({ password : event.target.value })   
    }
    handleSubmit = event =>{
        event.preventDefault()

        let bodyFormData = new FormData()
        bodyFormData.set('email', this.state.email)
        bodyFormData.set('password', this.state.password)

        axios.post(process.env.REACT_APP_API_URL+'/login', bodyFormData)
            .then(res => {
                localStorage.setItem('token' , res.data.data.token)
                this.setState({redirect: true})
            })
            .catch(error =>{
                //console.log(error);
                if (error.response.status === 401) {
                    this.setState({errors : error.response.data.errors})
                }
            })
    }

    render() {
        if(this.state.redirect === true){
            return (<Redirect to="Home" />)
        }
        return (
            <div className="login wrapper-90">
                <LogoLogin />
                <form method="POST" onSubmit={this.handleSubmit}>
                    <label className="text-center pb-2" htmlFor="email" id="label-login">Adresse e-mail</label>
                    <input type="text" id="email" onChange={this.handleEmailChange} placeholder="Identifiant"/>
                    <p className="text-alert">{ this.state.errors.email ?  <span> <i className="ico__sm ico--ib ico--alert mr-xs"></i>{ this.state.errors.email }</span> : ''}</p>

                    <label className="text-center pb-2" htmlFor="password" id="label-password">Mot de passe</label>
                    <input type="password" id="password" onChange={this.handlePasswordChange} placeholder="Mot de passe"/>
                    <p className="text-alert"> { this.state.errors.password ? <span> <i className="ico__sm ico--ib ico--alert mr-sm"></i>{ this.state.errors.password }</span> : ''}</p>
                    
                    <Btn btnCss="btn btn-primary mt-md" type="submit" id="password">Se connecter</Btn>

                   <p className="text-alert"> { this.state.errors.error ? <span> <i className="ico__sm ico--ib ico--alert mr-sm"></i>{ this.state.errors.error }</span> : ''}</p>
                </form>
            </div>

        )
    }
}

export default Login