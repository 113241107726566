import React, { Component} from 'react'
import { withRouter } from 'react-router-dom'

class BtnBack extends Component {
    
    constructor(props){
        super(props)
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        return (
            <button className="btn-reset btn-back" onClick={this.goBack}><i className="btn-back__ico"></i> Retour</button>
        )
    }
}

export default withRouter(BtnBack)