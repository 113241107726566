import React, { Component, Fragment } from "react";
import Btn from "../../../components/atom/Btn";
import axios from "axios";
import Breadcrumb from "../../../components/organism/Breadcrumb";

export default class TesteurLoraSaisi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            retourData: []
        };
    }

    handleSerialChange = (event) => {
        this.setState({ serial: event.target.value.toUpperCase() });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/loRa/testeur",
                bodyFormData,
                config
            )
            .then((res) => {
                if (res.data.data.trouve === true) {
                    this.props.history.push({
                        pathname: "/TesteurLoraDetail",
                        state: { 
                            detail: res.data.data , 
                            serial: this.state.serial
                        }
                    });
                } else {
                    this.props.history.push({
                        pathname: "/TesteurLoraNonVu",
                        state: { serial: this.state.serial }
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/TesteurLoraInconnu",
                    state: {
                        serial: this.state.serial
                    }
                });
            });
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="Testeur loRaWAN" ico="ico--testeur" />

                <div className="vertical-center text-center wrapper-90">
                    <div className="ico__xl ico--code ico--center"></div>

                    <p className="vertical-center__item">
                        Veuillez saisir le n° de série du testeur LoRaWAN.
                    </p>

                    <form
                        className="vertical-center__item"
                        method="POST"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            type="text"
                            id="serial"
                            onChange={this.handleSerialChange}
                            placeholder="N° de boitier"
                        />
                        {this.state.errors.serial ? (
                            <div> {this.state.errors.serial}</div>
                        ) : (
                            ""
                        )}

                        <Btn
                            btnCss="btn btn-primary mt-md"
                            type="submit"
                            id="submit"
                        >
                            Suivant
                        </Btn>
                    </form>

                    <blockquote>
                        *Le numéro de série du boitier se compose de 8
                        caractères : chiffres ou lettres de A à F. Il ne faut
                        pas d’espace. * Exemple : 219003A0
                    </blockquote>
                </div>
            </Fragment>
        );
    }
}