import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/organism/Breadcrumb'

class TesteurSigfoxInconnu extends Component {

    constructor(props){
        super(props)
        this.state = {
            token: this.props.location.state.token,
            errors: [],
        }
    }
    
    render() {
        return (
            <Fragment>
                <Breadcrumb titre='Testeur Sigfox' ico={sessionStorage.getItem('icoMenu')} />
                <div className="vertical-center wrapper-90">
                    <div className="ico__xl ico--alert ico--center"></div>
                    <h3 className="text-alert text-center ">{ this.state.token } </h3>
                    <h3 className="vertical-center__item text-center text-alert mb-xxxl">Erreur : Le numéro de testeur Sigfox { this.state.token } saisi est inconnu.</h3>
             
                    <Link className="btn btn-primary text-center mt-xxxl" to="TesteurSigfoxSaisi">  Entrer un nouveau identifiant de testeur Sigfox </Link>

                </div>
            </Fragment>
        )
    }
}

export default TesteurSigfoxInconnu