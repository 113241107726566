import React, { Component, Fragment } from "react";
import LeakageReleve from "./components/LeakageReleve";
import Breadcrumb from "../../components/organism/Breadcrumb";
import Spinner from "../../components/Spinner";
import axios from "axios";
import BtnCircle from "../../components/atom/BtnCircle";
import { Link } from "react-router-dom";

class LeakageDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            detail: this.props.location.state.detail,
            errors: []
        };
    }

    getIconFromText(color) {
        switch (color) {
            case "vert":
                return "bubble__ico--succes";
            case "rouge":
                return "bubble__ico--alert";
            case "orange":
                return "bubble__ico--warning";
            default:
                return null;
        }
    }

    async handleReload(event) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let bodyFormData = new FormData();
        bodyFormData.set("serial", this.state.detail.serial);
        bodyFormData.set("menuElementId", sessionStorage.getItem("menu"));

        axios
            .post(
                process.env.REACT_APP_API_URL + "/Leakage/detail",
                bodyFormData,
                config
            )
            .then((res) => {
                document.getElementById("spinnerLeakageReload").style.display =
                    "none";
                if (res.data.data.trouve === true) {
                    this.setState({ detail: res.data.data });
                } else {
                    this.props.history.push({
                        pathname: "/LeakageNonVu",
                        state: { token: res.data.data.token }
                    });
                }
            })
            .catch((error) => {
                this.props.history.push({
                    pathname: "/LeakageInconnu",
                    state: {
                        token: this.state.serial
                    }
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb titre="Leakage" ico="ico--leakage" />
                <Spinner spinnerId="spinnerLeakageReload"></Spinner>

                <div className="card card--border">
                    <h1 className="primary">
                        Capteur innondation n°{this.state.detail.serial}
                    </h1>

                    <p className="mb-xs mt-md">
                        <i className=" ico--ib ico__sm sm ico--lora mr-xs"></i>
                        N° loRaWAN :
                        <strong> {this.state.detail.lora.devEui}</strong>
                    </p>

                    <p>
                        <i className=" ico--ib ico__sm sm ico--sigfox mr-xs"></i>
                        N° Sigfox :
                        <strong> {this.state.detail.sigfox.serial}</strong>
                    </p>
                </div>

                <h2>Etat des communications radio</h2>

                <div className="card">
                    <ul className="bubble mt-0">
                        <li>
                            loRaWAN n°
                            <strong> {this.state.detail.lora.devEui}</strong> 
                        </li>
                        <li>
                            <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                            <span>
                                Dernier envoi :
                                <strong>
                                    {this.state.detail.lora.dateReleve}
                                </strong>
                            </span>
                        </li>

                        <li>
                            <i className={`bubble__ico ${this.getIconFromText( this.state.detail.lora.couleurEtatRadio )}`} ></i>
                            <span className="bubble__content">
                                Etat : <strong> {this.state.detail.lora.texteEtatRadio} </strong>
                            </span>
                        </li>

                        <li>
                            Nombre de stations reçues : <strong>  {this.state.detail.lora.nbStations}</strong>
                        </li>

                    </ul>
                </div>

                <div className="card">
                    <ul className="bubble mt-0">
                        <li>
                            Sigfox n°
                            <strong> {this.state.detail.sigfox.serial}</strong>
                        </li>
                        <li>
                            <i className=" ico--ib ico__sm sm ico--calendar mr-xs"></i>
                            <span>
                                Dernier envoi :
                                <strong>
                                    {this.state.detail.sigfox.dateReleve}
                                </strong>
                            </span>
                        </li>

                        <li>
                            <i
                                className={`bubble__ico ${this.getIconFromText(
                                    this.state.detail.sigfox.couleurEtatRadio
                                )}`}
                            ></i>
                            <span className="bubble__content">
                                Etat :
                                <strong> {this.state.detail.sigfox.texteEtatRadio}</strong>
                            </span>
                        </li>
                        <li>
                            Nombre de stations reçues : <strong> {this.state.detail.sigfox.nbStations}</strong>
                        </li>
                    </ul>
                </div>

                <div className="push-bottom">
                    <h1>Etat des capteurs</h1>

                    <LeakageReleve
                        temperature={this.state.detail.releves.temperature}
                        etatSonde1={this.state.detail.releves.etatSonde1}
                        debutAlarmeSonde1={ this.state.detail.releves.debutAlarmeSonde1}
                        etatSonde2={this.state.detail.releves.etatSonde2}
                        debutAlarmeSonde2={ this.state.detail.releves.debutAlarmeSonde2}
                        dateReleve={this.state.detail.releves.dateReleve}
                    />
                </div>

                <div className="wrapper-100">
                    <div className="nav-bottom">
                        <div className="container btn-circle__container btn-circle__container--centered">
                            {
                                <BtnCircle btnCss="btn-circle--reload">
                                    <Link
                                        to="#"
                                        onClick={() => {
                                            document.getElementById(
                                                "spinnerLeakageReload"
                                            ).style.display = "block";
                                            this.handleReload();
                                        }}
                                    >
                                        Rafraîchir
                                    </Link>
                                </BtnCircle>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default LeakageDetail;
